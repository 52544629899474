import React from "react";
import { Link } from "react-router-dom"; // Ensure Link is correctly imported

//import MobileStepper from "@material-ui/core/MobileStepper";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import "./Home.css";
import { Container, Grid, Box, Typography, Button } from "@mui/material"; // Example with Material-UI

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

import Header from "../components/Header"; // Import the Header component
import Footer from "../components/Footer";
// Collection of images with their labels and paths

const Projects = () => {
  const trainingRecords = [
    {
      organization: "UDACITY",
      certificate: "Data Science Fundamental",
      duration: "July to August, 2024",
    },
    {
      organization: "UNCTAD",
      certificate:
        "Trade and Gender Linkages with a focus on the Least Developed Countries",
      duration: "13 June –July 17, 2022",
    },
    {
      organization: "Iman Islamic College",
      certificate: "Advanced Diploma in Islamic Studies",
      duration: "Sep 2018- June 2020",
    },
    {
      organization: "AGRODEP-IFPRI Held at Dakar",
      certificate: "Regional Trade clinic",
      duration: "Feb 7-9, 2017",
    },
  ];
  return (
    <div>
      <Header /> {/* Include the Header component here */}
      <div style={{ textAlign: "center", marginTop: "2rem" }}></div>
      {/* Services Section */}
      <div className="services-section">
        <h2>Profile</h2>

        {/* mission, vision,  Section */}
        <div>
          <Grid container spacing={4}>
            {/* Left Column: Text/Info */}
            <Grid item xs={12} md={8}>
              <div className="user-section">
                <h3>Dr Hassen Beshir</h3>
                <p>
                  He is the general manager of the company. He is associate
                  professor in Applied and Agricultural Economics and
                  Econometrics. The Manager has been specialized in Applied and
                  Agricultural Economics and Econometrics. He is a senior
                  consultant in Development Economics, Investment and Business.
                  The manager has an extensive practical and empirical works in
                  agricultural production and technology, growth, development,
                  and equity, and poverty measurement and analysis. The research
                  and development interests of the company and the affiliate
                  include impact evaluation, production efficiency, project and
                  program planning, monitoring and evaluation, and agricultural
                  marketing, Economic Development, Food and Nutrition Security
                  Analysis, Development Impact Evaluation, Economic Modeling,
                  Social and Economic Opinion Survey, Business Plan and project
                  feasibility analysis, Environmental Impact Assessment,
                  Customer Satisfaction, Institutional Governance and Horizontal
                  accountability.The overarching goal of the research activities
                  are to promote competent and sustainable production through
                  understanding of fundamental bottlenecks and
                  scientifically-sound management practices in Ethiopia and
                  abroad.
                </p>
              </div>
              <div className="user-section">
                <h3>EDUCATIONAL/PROFESSIONAL BACKGROUND</h3>
                <p></p>
                <ul>
                  <li>
                    PhD Degree in Applied and Agri. Economics received from
                    Haramaya University on May 21, 2011. (2008-2011)
                  </li>
                  <li>
                    MSc Degree in Applied and Agri Economics from Haramaya
                    University received in Mar 2000 (1997-2000)
                  </li>
                  <li>
                    BSc Degree in Applied and Agri Economics from Alemaya
                    University in Nov, 1991 (1987-1991)
                  </li>
                </ul>
              </div>
              <div className="user-section">
                <h3>TRAINING</h3>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    margin: "20px 0",
                  }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Organization and Place of Training
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Certificates Awarded / Title of Workshop
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Duration
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {trainingRecords.map((record, index) => (
                      <tr key={index}>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {record.organization}
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {record.certificate}
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {record.duration}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer /> {/* Include the Header component here */}
    </div>
  );
};

export default Projects;
