import React from "react";
import { Link } from "react-router-dom"; // Ensure Link is correctly imported

//import MobileStepper from "@material-ui/core/MobileStepper";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import "./Home.css";
import { Container, Grid, Box, Typography, Button } from "@mui/material"; // Example with Material-UI

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

import Header from "../components/Header"; // Import the Header component
import Footer from "../components/Footer";
// Collection of images with their labels and paths

const Projects = () => {
  return (
    <div>
      <Header /> {/* Include the Header component here */}
      <div style={{ textAlign: "center", marginTop: "2rem" }}></div>
      {/* Services Section */}
      <div className="services-section">
        <h2>Projects</h2>

        {/* mission, vision,  Section */}
        <div>
          <Grid container spacing={4}>
            {/* Left Column: Text/Info */}
            <Grid item xs={12} md={8}>
              <div className="user-section">
                <h3>Business Strategy</h3>
                <p>Introduction to IoT and AI solutions, HW and SW</p>
              </div>
              <div className="user-section">
                <h3>Market Analysis</h3>
                <p>Sensor Technologies: Applications, Functionality</p>
              </div>
              <div className="user-section">
                <h3>Digital Transformation</h3>
                <p>Raspberry Pi and Python programming</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer /> {/* Include the Header component here */}
    </div>
  );
};

export default Projects;
