import React from "react";
import "./Home.css";
import { Container, Grid, Box, Typography, Button } from "@mui/material"; // Example with Material-UI
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
const Dashboard = () => {
  return (
    <div>
      <Header />
      <Container style={{ marginTop: "2rem" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" paragraph>
          Focused assessment, One Vision-One Plan, Compact; Use of the studies
          to frame Monitoring and Support for Inclusive and Participatory
          transformation.
        </Typography>

        <Typography variant="body1" paragraph>
          The overarching goal of the research activities are to promote
          competent and sustainable production through understanding of
          fundamental bottlenecks and scientifically-sound management practices
          in Ethiopia and abroad.
        </Typography>

        <Typography variant="h5" component="h1" gutterBottom>
          MISSION OF THE FIRM
        </Typography>

        <Typography variant="body1" paragraph>
          The firm has a mission to investigate, survey, study and plan reliable
          and sustainable development systems to full customers’ satisfaction,
          and beneficiaries.
        </Typography>

        <Typography variant="h5" component="h1" gutterBottom>
          VISION
        </Typography>

        <Typography variant="body1" paragraph>
          The vision of the firm is to be one of the nationally and regionally
          competent consulting firms in the area of economic development, food
          security, trade and investment, environmental impact assessment and
          statistics, evaluation, feasibility analysis, development and project
          planning.
        </Typography>

        <Typography variant="h5" component="h1" gutterBottom>
          VALUES
        </Typography>

        <Typography variant="body1" paragraph>
          The firm is led by core values, which underpin our client engagements:
          <ul>
            <li>
              Client service first - excellent, flexible professional service;
            </li>
            <li>We are focused on creating and adding value;</li>
            <li>
              We respect clients' need for our independence and objectivity;
            </li>
            <li>
              {" "}
              We cherish integrity, matching words with deeds and telling
              clients like it is;
            </li>
            <li>
              We ensure transparency in our business - what you see is what you
              get;
            </li>
            <li>
              Teamwork - combining own and client capabilities for the best
              results;
            </li>
            <li>
              Results delivery - we focus on achieving tangible results that
              impact on our clients' interest.
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" component="h1" gutterBottom>
          THE DIFFERENCE
        </Typography>

        <Typography variant="body1" paragraph>
          While the roots of our experience lie in working with all potential
          professional firms, and general management, we differentiate ourselves
          through:
          <ul>
            <li>Our senior level principals work directly with clients;</li>
            <li>
              Our senior level principals have extensive production, marketing
              and consumption process and study, train and advisory consulting
              experience;
            </li>
            <li>
              Our service extends from business strategy to implementation;
              rather than just writing reports, we monitor and evaluate our
              study and design;
            </li>
            <li>
              {" "}
              We work closely with our clients, involving them in the decision
              process and building capability as well as readiness for action;
            </li>
            <li>
              We execute assignments both big and small; we flex our capacity to
              suit our clients’ needs;
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" component="h1" gutterBottom>
          EXPERTISE OF THE FIRM
        </Typography>

        <Typography variant="body1">
          The professional expertise available from the firm:
        </Typography>
        <ul>
          <li>
            {" "}
            Our multi-disciplinary expertise cover the fields of strategy,
            operations, crops development, watershed management, socio-economic
            study, study and design and construction supervision of water
            related projects;
          </li>
          <li>
            Our multi-sectored professional experience within irrigation
            services, water supply system, drainage, watershed, and other water
            related projects;
          </li>
          <li>
            Project management: young but Proven experience in planning and
            managing from micro to complex and large projects in diverse sectors
            and client environments;
          </li>
          <li>
            {" "}
            Flexible capacity around a stable core: The firm is built around a
            core team of principals and associates.
          </li>
          <li>
            Decision support latest software: Extensive experience developing
            automated software solutions to support critical organization
            decision process. Integration with existing information systems
            ensures that these transform organizational decision processes.
          </li>
        </ul>

        {/* mission, vision,  Section */}
        <div>
          <Grid container spacing={4}>
            {/* Left Column: Text/Info */}
            <Grid item xs={12} md={4}>
              <Link
                to="/profile" // home
                style={{
                  textDecoration: "none", // Removes underline
                  color: "inherit", // Inherits the Typography color
                }}
              >
                <h3>Dr Hassen Beshir</h3>

                <img
                  src="./dr_hassen.png"
                  width={200}
                  alt="AYAT Logo"
                  height={170}
                />
                <Typography>
                  Founder and CEO <br /> Agricultural Economics
                </Typography>
              </Link>
            </Grid>
            {/* Left Column: Text/Info */}
            <Grid item xs={12} md={4}>
              <h3>Seada Abera</h3>
              <img src="./seada_abera.jpeg" width={200} alt="AYAT Logo" />
              <Typography>Data Scientist</Typography>
            </Grid>

            {/* Left Column: Text/Info */}
            <Grid item xs={12} md={4}>
              <h3>Salahadin Seid</h3>
              <img
                src="./salahadin.jpeg"
                width={200}
                height={170}
                alt="AYAT Logo"
                style={{
                  borderStartEndRadius: "5px",

                  backgroundColor: "#f0f0f0",
                }}
              />
              <Typography>Digitalization & Infrastructure</Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingTop: 30 }}></div>
      </Container>
      <Footer />
    </div>
  );
};

export default Dashboard;
