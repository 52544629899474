import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Import Swiper CSS
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const ImageSlider = () => {
  const images = [
    "https://www.shutterstock.com/image-photo/using-tablet-pc-consultant-between-600nw-2260960137.jpg?text=Leading to Unleash the Innovation Potential for Digital Transformation+1",
    "https://www.shutterstock.com/image-photo/document-management-system-dms-being-600nw-2135267769.jpg?text=Slide+2",
    "https://www.shutterstock.com/image-photo/using-tablet-pc-consultant-between-600nw-2260960137.jpg",
  ];

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000 }}
      loop
      style={{ width: "100%", height: "600px" }}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img
            src={image}
            alt={`Slide ${index + 1}`}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImageSlider;
