import React from "react";
import { Container, Typography, Grid } from "@mui/material"; // Example with Material-UI
import Header from "../components/Header";
import Footer from "../components/Footer";

const Services = () => {
  return (
    <div>
      <Header />
      <Container style={{ marginTop: "2rem" }}>
        <Typography variant="h4" gutterBottom>
          Services
        </Typography>
        <Typography paragraph>
          FOCUS TRADING PLC has been involved in agricultural and development
          related projects production, marketing and consumption process and
          research, train and advisory consulting. It will expand its business
          areas in its future expansion, like construction and road.
        </Typography>
        <p>..</p>

        {/* Services Section */}
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={5.9}
            style={{ backgroundColor: "#f0f0f0", padding: "1rem" }}
          >
            <Typography variant="h6">
              {" "}
              <a href="">Training</a>
            </Typography>
            <Typography paragraph>
              Graduate program (PhD and MSC) in Wollo, Jimma, BuleHora and
              Gonder Universities in the department of Economics, Agricultural
              Economics and Tossa College of Economics o Research methods for
              socio-economists, macroeconomics, microeconomics, production
              economics and marketing and price analysis, project planning,
              identification and analysis, econometrics, gender and development
            </Typography>
            <Typography paragraph>
              Undergraduate program: Microeconomics, macroeconomics, finance,
              Farm management, agricultural Project planning and analysis,
              Economics and econometrics
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5.9}
            style={{
              backgroundColor: "#f0f0f0",
              padding: "0.5rem",
              margin: "2px",
            }}
          >
            <Typography variant="h6">
              <a href="">Research and Advise</a>
            </Typography>
            <Typography paragraph>
              {" "}
              Advisor More Than 65 MSc students; Advisor More Than ten PhD
              Students More Than 35 MSc students internalexaminer; More Than 20
              MSc students External examiner
              <ul>
                <li>Prepare technical proposal</li>
                <li>Design research methodology</li>
                <li>Design data collection tools</li>
                <li> Supervise, monitor and Conduct big data collection </li>
                <li> Conduct data analysis using excel, STATA, R and SPSS </li>
                <li>Report writing and presentation</li>
              </ul>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5.9}
            style={{
              backgroundColor: "#f0f0f0",
              padding: "0.5rem",
              margin: "1px",
            }}
          >
            <Typography variant="h6">
              <a href="">Technical notes and module preparation (Books)</a>
            </Typography>
            <Typography paragraph>
              <ul>
                <li>
                  Hassen Beshir, 2014. Agricultural Project planning and
                  analysis.
                </li>
                <li>Economics of Farm Management, 2020 </li>
              </ul>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5.9}
            style={{
              backgroundColor: "#f0f0f0",
              padding: "0.5rem",
              margin: "1px",
            }}
          >
            <Typography variant="h6">
              <a href="">Consulting</a>
            </Typography>
            <Typography paragraph>
              Deliver expert guidance and solutions across diverse domains— such
              as technology, education, business, and research—empowering
              clients to achieve their goals through innovative strategies,
              tailored insights, and actionable plans.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5.9}
            style={{
              backgroundColor: "#f0f0f0",
              padding: "0.5rem",
              margin: "2px",
            }}
          >
            <Typography variant="h6">
              <a href="https://www.tutor.qmindpro.com">R&D</a>
            </Typography>
            <Typography paragraph>
              Support business organizations, start-ups, entrepreneurs, and
              high-tech companies in international expansion planning, joint
              ventures, joint grant writing, joint research on digital
              innovation and transformation, alliances, and other cooperation
              methods.
            </Typography>
          </Grid>
        </Grid>
        <p></p>
      </Container>
      <Footer />
    </div>
  );
};

export default Services;
